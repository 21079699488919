import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import 'devextreme/data/odata/store';
import { ConnectionService } from 'src/app/services/connection.service';
import { IConnection } from 'src/app/interfaces/connection.interface';

@Component({
  selector: 'app-connections',
  templateUrl: 'connection.component.html',
})
export class ConnectionComponent implements OnInit, OnDestroy {
  @Input() companyId!: number;
  @Input() receiverId!: number;
  visible: boolean;
  connections: IConnection[];
  interval: any;
  seedingMilliseconds: number;

  constructor(private connectionService: ConnectionService) {
    this.connections = [];
    this.interval = {};
    this.visible = false;
    this.seedingMilliseconds = 60 * 1000;
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {}

  stopGettingConnections(): void {
    this.visible = false;

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async startGettingConnections(): Promise<void> {
    this.visible = true;

    await this.getConnections();

    this.interval = setInterval(() => {
      this.getConnections();
    }, this.seedingMilliseconds);
  }

  async getConnections(): Promise<void> {
    await this.connectionService
      .getConnections(this.companyId, this.receiverId)
      .then((connections: IConnection[]) => {
        this.connections = connections;
        console.log('CONN => companyId: ', this.companyId);
        console.log('CONN => instanceId: ', this.receiverId);
      });
  }
}
