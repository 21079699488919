import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import 'devextreme/data/odata/store';
import { ReceiverService } from 'src/app/services/receiver.service';
import { ActivatedRoute } from '@angular/router';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { IPanelStatusList } from 'src/app/interfaces/panel-status.interface';
import { PanelStatusList } from 'src/app/models/panel-status';
import { ReceiverCardComponent } from './card/card.component';
import notify from 'devextreme/ui/notify';
import { ReceiverCreateComponent } from './create/create.component';
import { isAdmin } from 'src/app/services/authentication.service';

@Component({
  templateUrl: 'receiver.component.html',
  styleUrls: ['receiver.component.scss'],
})
export class ReceiverComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(ReceiverCardComponent)
  receiverComponentList!: QueryList<ReceiverCardComponent>;

  @ViewChild(ReceiverCreateComponent)
  receiverCreateComponent!: ReceiverCreateComponent;

  isAdmin = isAdmin();

  companyId: number | undefined;
  companyName: string | undefined;
  clicked: boolean;
  receivers: IReceiverSettings[];
  filteredReceivers: IReceiverSettings[];
  totalPanelStatusList: IPanelStatusList;
  anyoneRunning: boolean;
  seedingMilliseconds: number;
  interval: any;

  searchText = '';

  constructor(
    private receiverService: ReceiverService,
    private routerActived: ActivatedRoute
  ) {
    this.receivers = [];
    this.filteredReceivers = [];
    this.clicked = false;
    this.totalPanelStatusList = new PanelStatusList();
    this.anyoneRunning = false;
    this.seedingMilliseconds = 60 * 1000 + 50;
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.actualizePanelsTotal(this.receiverComponentList.toArray());
    }, 5 * 1000);

    this.interval = setInterval(() => {
      this.actualizePanelsTotal(this.receiverComponentList.toArray());
    }, this.seedingMilliseconds);
  }

  ngOnInit(): void {
    this.getReceivers();
  }

  getReceivers(): void {
    this.routerActived.params.subscribe((params) => {
      this.companyId = Number(params['company-id']);

      if (this.companyId) {
        this.receiverService
          .getReceivers(this.companyId)
          .then((receivers: IReceiverSettings[]) => {
            this.filteredReceivers = this.receivers = receivers;

            this.companyName = receivers[0]?.company?.name;
          })
          .catch((e) => {});
      } else {
        this.receiverService
          .getAllReceivers()
          .then((receivers: IReceiverSettings[]) => {
            this.filteredReceivers = this.receivers = receivers;
          })
          .catch((e) => {});
      }
    });
  }

  create(): void {
    this.receiverCreateComponent?.showDialog();
  }

  actualizePanelsTotal(array: ReceiverCardComponent[]): void {
    this.totalPanelStatusList = new PanelStatusList();
    this.anyoneRunning = false;

    array?.forEach((receiverComponent: ReceiverCardComponent) => {
      if (receiverComponent.receiver.running) {
        this.anyoneRunning = true;

        this.totalPanelStatusList.onlineCount +=
          receiverComponent.receiver.panelStatusList?.onlineCount || 0;

        this.totalPanelStatusList.offlineCount +=
          receiverComponent.receiver.panelStatusList?.offlineCount || 0;

        this.totalPanelStatusList.keepFailCount +=
          receiverComponent.receiver.panelStatusList?.keepFailCount || 0;

        this.totalPanelStatusList.unknowCount +=
          receiverComponent.receiver.panelStatusList?.unknowCount || 0;
      }
    });
  }

  onCreated(e: any): void {
    this.getReceivers();
  }

  onDeleted(e: any): void {
    const id = Number(e);

    this.filteredReceivers = this.receivers = this.receivers.filter(
      (receiver) => receiver.id !== id
    );
  }

  onSearch(event: any): void {
    if (event) {
      this.searchText = event.toLowerCase();

      this.filteredReceivers = this.receivers.filter(
        (receiver) =>
          receiver?.name?.toLowerCase().indexOf(this.searchText) !== -1 ||
          receiver?.description?.toLowerCase().indexOf(this.searchText) !==
            -1 ||
          receiver?.receiverIP?.toLowerCase().indexOf(this.searchText) !== -1 ||
          receiver?.receiverPort
            ?.toString()
            .toLowerCase()
            .indexOf(this.searchText) !== -1 ||
          receiver?.servicePort
            ?.toString()
            .toLowerCase()
            .indexOf(this.searchText) !== -1 ||
          receiver?.type?.toLowerCase().indexOf(this.searchText) !== -1 ||
          receiver?.instanceID?.toLowerCase().indexOf(this.searchText) !== -1 ||
          receiver?.company?.name?.toLowerCase().indexOf(this.searchText) !== -1
      );
    } else {
      this.filteredReceivers = this.receivers;
    }
  }
}
