import { ICompany } from '../interfaces/company.interface';
import { ReceiverSettings } from './receiver-settings';

export class Company implements ICompany {
  id = 0;
  code = '';
  name?: string | undefined;
  description?: string | undefined;
  connectionString?: string | undefined;
  receiversCount = 0;
  receiversRunningCount = 0;
}
