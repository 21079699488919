<dx-popup
  [showTitle]="true"
  title="Criar receiver"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
  height="calc(100vh - 100px)"
>
  <div *dxTemplate="let data of 'content'" class="dx-scrollable-content">
    <dx-scroll-view width="100%" height="85%">
      <app-receiver-form
        [receiver]="receiver"
        [creating]="true"
      ></app-receiver-form>
    </dx-scroll-view>
    <div
      class="col-md-12 d-flex align-items-end justify-content-center mt-2"
      style="height: 15%"
    >
      <dx-button
        stylingMode="contained"
        text="Cancelar"
        type="danger"
        (onClick)="popupVisible = false"
      >
      </dx-button>
      <dx-button
        class="ml-2"
        stylingMode="contained"
        text="Criar"
        type="success"
        (onClick)="createReceiver()"
        [useSubmitBehavior]="true"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
