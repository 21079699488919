<dx-popup
  [showTitle]="true"
  title="{{ receiver.name }}"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'" class="dx-scrollable-content">
    <dx-scroll-view width="100%" height="100%">
      <app-receiver-form [receiver]="receiver"></app-receiver-form>
    </dx-scroll-view>
  </div>
</dx-popup>
