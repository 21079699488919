import { ISerialSettings } from '../interfaces/serial-settings.interface';
import { SerialProtocol } from '../enums/serial-protocol';
import SerialTransmissionType from '../enums/serial-transmission-type';
import { StopBits } from '../enums/stop-bits';
import { Parity } from '../enums/parity';
import { Handshake } from '../enums/hanshake';

export class SerialSettings implements ISerialSettings {
  id = 0;
  ack = 6;
  messageTerminator = 13;
  mSecondsToRead = 300;
  secondsToSendAck = 30;
  secondsToHaveHack = 30;
  logging = false;
  trasmissionMode = 'L';
  serialProtocol = SerialProtocol.Surgard;
  serialTransmissionType = SerialTransmissionType.ComPort;
  comPort = 'COM';
  baudRate = 1200;
  dataBits = 8;
  stopBits = StopBits.One;
  parity = Parity.None;
  handshake = Handshake.None;
  hasDtr = false;
}
