<form name="receiver-form" class="form-horizontal col-md-12">
  <div class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input">Instância</label>
    <input
      type="text"
      class="form-control col-md-2"
      maxlength="5"
      placeholder="Instância"
      [class.is-invalid]="!receiver.instanceID"
      [(ngModel)]="receiver.instanceID"
      name="instanceID"
      required
      [readonly]="!editable"
    />

    <label class="col-md-1 col-form-label" for="text-input">Empresa</label>
    <select
      class="col-md-2 browser-default custom-select"
      [class.is-invalid]="!receiver.companyId"
      [(ngModel)]="receiver.companyId"
      name="companyId"
      required
      [disabled]="!editable"
    >
      <option *ngFor="let company of companies" [ngValue]="company.id">
        {{ company.name }}
      </option>
    </select>

    <label class="col-md-1 col-form-label" for="text-input">Nome</label>
    <input
      type="text"
      class="form-control col-md-4"
      placeholder="Nome"
      [class.is-invalid]="!receiver.name"
      [(ngModel)]="receiver.name"
      name="name"
      required
      [readonly]="!editable"
    />
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input">Descrição</label>
    <input
      type="text"
      class="form-control col-md-10"
      placeholder="Descrição"
      [class.is-invalid]="!receiver.description"
      [(ngModel)]="receiver.description"
      name="description"
      required
      [readonly]="!editable"
    />
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input"
      >Idenficador de Cliente</label
    >
    <input
      type="text"
      class="form-control col-md-1"
      maxlength="1"
      placeholder="Idenficador de Cliente"
      [class.is-invalid]="receiver.clientPrefix === undefined"
      [(ngModel)]="receiver.clientPrefix"
      name="clientPrefix"
      required
      [readonly]="!editable"
    />

    <label class="col-md-1 col-form-label" for="text-input">Porta</label>
    <input
      type="number"
      class="form-control col-md-2"
      placeholder="Porta"
      [class.is-invalid]="!receiver.receiverPort"
      [(ngModel)]="receiver.receiverPort"
      name="receiverPort"
      required
      [readonly]="!editable"
    />

    <label class="col-md-2 col-form-label" for="text-input"
      >Porta de Serviço</label
    >
    <input
      type="number"
      class="form-control col-md-2"
      placeholder="Porta de Serviço"
      [class.is-invalid]="receiver.servicePort === undefined"
      [(ngModel)]="receiver.servicePort"
      name="servicePort"
      required
      [readonly]="!editable"
    />

    <label class="col-md-1 col-form-label" for="text-input">Tipo</label>
    <input
      type="text"
      class="form-control col-md-1"
      maxlength="1"
      placeholder="Tipo do Receiver"
      [class.is-invalid]="!receiver.receiverType"
      [(ngModel)]="receiver.receiverType"
      name="receiverType"
      required
      [readonly]="!editable"
    />
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input"
      >IDs de Integração</label
    >
    <input
      type="text"
      class="form-control col-md-3"
      placeholder="IDs de Integração"
      [class.is-invalid]="
        !receiver.integrationsIDs || receiver.integrationsIDs.length === 0
      "
      [ngModel]="receiver.integrationsIDs"
      (ngModelChange)="updatingIntegrationsIds($event)"
      name="integrationsIDs"
      required
      [readonly]="!editable"
    />

    <label class="col-md-1 col-form-label" for="text-input">Buffer</label>
    <input
      type="text"
      class="form-control col-md-2"
      placeholder="Nome do Buffer"
      [class.is-invalid]="!receiver.bufferName"
      [(ngModel)]="receiver.bufferName"
      name="bufferName"
      required
      [readonly]="!editable"
    />

    <label class="col-md-2 col-form-label" for="text-input"
      >Eventos por Segundo</label
    >
    <input
      type="number"
      class="form-control col-md-2"
      placeholder="Eventos por Segundo"
      [class.is-invalid]="!receiver.eventsPerSecond"
      [(ngModel)]="receiver.eventsPerSecond"
      name="eventsPerSecond"
      required
      [readonly]="!editable"
    />
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input">Protocolo</label>
    <select
      class="col-md-3 browser-default custom-select"
      [class.is-invalid]="!receiver.type"
      [ngModel]="receiver.type"
      (ngModelChange)="onIsSerialChange($event)"
      name="type"
      required
      [disabled]="!editable"
    >
      <option
        *ngFor="let item of getReceiverProtocols()"
        [ngValue]="item.value"
      >
        {{ item.key }}
      </option>
    </select>

    <label class="col-md-2 col-form-label" for="text-input"
      >Salvar dados via</label
    >
    <select
      class="col-md-2 browser-default custom-select"
      [(ngModel)]="receiver.workDataMethod"
      name="workDataMethod"
      required
      [disabled]="!editable"
    >
      <option *ngFor="let item of getWorkDataMethods()" [ngValue]="item.key">
        {{ item.value }}
      </option>
    </select>

    <label class="col-md-2 col-form-label" for="text-input">É Backup?</label>
    <select
      class="col-md-1 browser-default custom-select"
      [(ngModel)]="receiver.backupReceiver"
      name="backupReceiver"
      required
      [disabled]="!editable"
    >
      <option [ngValue]="true">SIM</option>
      <option [ngValue]="false">NÃO</option>
    </select>
  </div>
  <div *ngIf="receiver.workDataMethod == 1" class="form-group row">
    <label class="col-md-2 col-form-label" for="text-input">URL da API</label>
    <input
      type="text"
      class="form-control col-md-4"
      placeholder="URL da API"
      [class.is-invalid]="!receiver.apiUrl"
      [(ngModel)]="receiver.apiUrl"
      name="apiUrl"
      required
      [readonly]="!editable"
    />

    <label class="col-md-2 col-form-label" for="text-input">Token da API</label>
    <input
      type="text"
      class="form-control col-md-4"
      placeholder="Token da API"
      [class.is-invalid]="!receiver.apiToken"
      [(ngModel)]="receiver.apiToken"
      name="apiToken"
      required
      [readonly]="!editable"
    />
  </div>

  <div *ngIf="receiver.serialSettings != null">
    <hr />
    <div class="form-group row">
      <label class="col-md-2 col-form-label" for="text-input"
        >Protocolo Serial</label
      >
      <select
        class="col-md-3 browser-default custom-select"
        [ngModel]="receiver.serialSettings.serialProtocol"
        (ngModelChange)="setSerialDefaults($event)"
        name="serialProtocol"
        required
        [disabled]="!editable"
      >
        <option
          *ngFor="let item of getReceiverSerialProtocols()"
          [ngValue]="item.key"
        >
          {{ item.value }}
        </option>
      </select>

      <label class="col-md-3 col-form-label" for="text-input"
        >Método de recebimento</label
      >
      <select
        class="col-md-2 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.serialTransmissionType"
        name="serialTransmissionType"
        required
        [disabled]="!editable"
      >
        <option
          *ngFor="let item of getReceiverSerialTransmissionTypes()"
          [ngValue]="item.key"
        >
          {{ item.value }}
        </option>
      </select>

      <label class="col-md-1 col-form-label" for="text-input">Modo</label>
      <input
        type="text"
        class="form-control col-md-1"
        placeholder="Modo"
        [class.is-invalid]="!receiver.serialSettings.trasmissionMode"
        [(ngModel)]="receiver.serialSettings.trasmissionMode"
        name="trasmissionMode"
        required
        [readonly]="!editable"
      />
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label" for="text-input">ACK</label>
      <input
        type="number"
        class="form-control col-md-2"
        placeholder="Receiver ACK"
        [class.is-invalid]="!receiver.serialSettings.ack"
        [(ngModel)]="receiver.serialSettings.ack"
        name="ack"
        required
        [readonly]="!editable"
      />

      <label class="col-md-2 col-form-label" for="text-input">Terminador</label>
      <input
        type="number"
        class="form-control col-md-2"
        placeholder="Terminador"
        [class.is-invalid]="!receiver.serialSettings.messageTerminator"
        [(ngModel)]="receiver.serialSettings.messageTerminator"
        name="messageTerminator"
        required
        [readonly]="!editable"
      />

      <label class="col-md-3 col-form-label" for="text-input">Gera LOG</label>
      <select
        class="col-md-1 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.logging"
        name="logging"
        required
        [disabled]="!editable"
      >
        <option [ngValue]="true">SIM</option>
        <option [ngValue]="false">NÃO</option>
      </select>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label" for="text-input"
        >Segundos para enviar ACK</label
      >
      <input
        type="number"
        class="form-control col-md-1"
        placeholder="Segundos para enviar ACK"
        [class.is-invalid]="!receiver.serialSettings.secondsToSendAck"
        [(ngModel)]="receiver.serialSettings.secondsToSendAck"
        name="secondsToSendAck"
        required
        [readonly]="!editable"
      />

      <label class="col-md-3 col-form-label" for="text-input"
        >Segundos para receber o ACK</label
      >
      <input
        type="number"
        class="form-control col-md-1"
        placeholder="Segundos para ter o ACK"
        [class.is-invalid]="!receiver.serialSettings.secondsToHaveHack"
        [(ngModel)]="receiver.serialSettings.secondsToHaveHack"
        name="secondsToHaveHack"
        required
        [readonly]="!editable"
      />

      <label class="col-md-3 col-form-label" for="text-input"
        >Milliseconds para leitura</label
      >
      <input
        type="number"
        class="form-control col-md-1"
        placeholder="Milliseconds para leitura"
        [class.is-invalid]="!receiver.serialSettings.mSecondsToRead"
        [(ngModel)]="receiver.serialSettings.mSecondsToRead"
        name="mSecondsToRead"
        required
        [readonly]="!editable"
      />
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label" for="text-input">Stop Bits</label>
      <select
        class="col-md-2 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.stopBits"
        name="stopBits"
        required
        [disabled]="!editable"
      >
        <option
          *ngFor="let item of getReceiverSerialStopBits()"
          [ngValue]="item.key"
        >
          {{ item.value }}
        </option>
      </select>

      <label class="col-md-2 col-form-label" for="text-input">Paridade</label>
      <select
        class="col-md-2 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.parity"
        name="parity"
        required
        [disabled]="!editable"
      >
        <option
          *ngFor="let item of getReceiverSerialParity()"
          [ngValue]="item.key"
        >
          {{ item.value }}
        </option>
      </select>

      <label class="col-md-2 col-form-label" for="text-input">Handshake</label>
      <select
        class="col-md-2 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.handshake"
        name="handshake"
        required
        [disabled]="!editable"
      >
        <option
          *ngFor="let item of getReceiverSerialHandshake()"
          [ngValue]="item.key"
        >
          {{ item.value }}
        </option>
      </select>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label" for="text-input">Porta COM</label>
      <input
        type="text"
        class="form-control col-md-2"
        placeholder="Porta COM"
        [class.is-invalid]="!receiver.serialSettings.comPort"
        [(ngModel)]="receiver.serialSettings.comPort"
        name="comPort"
        required
        [readonly]="!editable"
      />

      <label class="col-md-1 col-form-label" for="text-input">Velocidade</label>
      <input
        type="number"
        class="form-control col-md-1"
        placeholder="Velocidade"
        [class.is-invalid]="!receiver.serialSettings.baudRate"
        [(ngModel)]="receiver.serialSettings.baudRate"
        name="baudRate"
        required
        [readonly]="!editable"
      />

      <label class="col-md-2 col-form-label" for="text-input">Data Bits</label>
      <input
        type="number"
        class="form-control col-md-1"
        placeholder="Data Bits"
        [class.is-invalid]="!receiver.serialSettings.dataBits === undefined"
        [(ngModel)]="receiver.serialSettings.dataBits"
        name="dataBits"
        required
        [readonly]="!editable"
      />

      <label class="col-md-2 col-form-label" for="text-input">Tem DTR?</label>
      <select
        class="col-md-1 browser-default custom-select"
        [(ngModel)]="receiver.serialSettings.hasDtr"
        name="hasDtr"
        required
        [disabled]="!editable"
      >
        <option [ngValue]="true">SIM</option>
        <option [ngValue]="false">NÃO</option>
      </select>
    </div>
  </div>
</form>
