import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import SerialTransmissionType from 'src/app/enums/serial-transmission-type';
import { SerialProtocol } from 'src/app/enums/serial-protocol';
import { WorkDataMethod } from 'src/app/enums/work-data-method';
import { ICompany } from 'src/app/interfaces/company.interface';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { ReceiverSettings } from 'src/app/models/receiver-settings';
import { SerialSettings } from 'src/app/models/serial-settings';
import { CompanyService } from 'src/app/services/company.service';
import { KeyValuePair } from 'src/app/utils/key-value-pair';
import { StopBits } from 'src/app/enums/stop-bits';
import { Parity } from 'src/app/enums/parity';
import { Handshake } from 'src/app/enums/hanshake';
import { ReceiverProtocol } from 'src/app/enums/receiver-protocol';

@Component({
  selector: 'app-receiver-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ReceiverFormComponent implements OnInit {
  @Input() receiver!: IReceiverSettings;
  @Input() editable!: boolean;
  @Input() creating!: boolean;

  companies: ICompany[];

  constructor(private companyService: CompanyService) {
    this.receiver = new ReceiverSettings();
    this.companies = new Array<ICompany>();
  }

  ngOnInit(): void {
    if (this.creating) {
      this.editable = true;
    }

    this.companyService.getAll().then((response) => {
      this.companies = response;

      if (!this.receiver.companyId && this.companies?.length > 0) {
        this.receiver.companyId = this.companies[0].id;
      }
    });
  }

  onIsSerialChange(event: any): void {
    this.receiver.type = event;

    switch (this.receiver.type) {
      case ReceiverProtocol.Serial:
        this.receiver.serialSettings = new SerialSettings();
        this.setSerialDefaults(this.receiver.serialSettings.serialProtocol);
        break;
      default:
        this.receiver.serialSettings = undefined;
        break;
    }
  }

  getReceiverProtocols(): KeyValuePair[] {
    return KeyValuePair.build(ReceiverProtocol);
  }

  getWorkDataMethods(): KeyValuePair[] {
    return KeyValuePair.build(WorkDataMethod);
  }

  getReceiverSerialProtocols(): KeyValuePair[] {
    return KeyValuePair.build(SerialProtocol);
  }

  getReceiverSerialTransmissionTypes(): KeyValuePair[] {
    return KeyValuePair.build(SerialTransmissionType);
  }

  getReceiverSerialStopBits(): KeyValuePair[] {
    return KeyValuePair.build(StopBits);
  }

  getReceiverSerialParity(): KeyValuePair[] {
    return KeyValuePair.build(Parity);
  }

  getReceiverSerialHandshake(): KeyValuePair[] {
    return KeyValuePair.build(Handshake);
  }

  setSerialDefaults(event: any): void {
    if (this.receiver.serialSettings) {
      this.receiver.serialSettings.serialProtocol = event;

      switch (this.receiver.serialSettings.serialProtocol) {
        case SerialProtocol.Ademco685:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.Bosch:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.CM1000:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 9600;
          break;
        case SerialProtocol.MCDI:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 9600;
          break;
        case SerialProtocol.MCDIExprecium:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.Surgard:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.SurgardSystem:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 9600;
          break;
      }
    }
  }

  updatingIntegrationsIds(value: any): void {
    if (!value || value.endsWith(',')) {
      return;
    }

    this.receiver.integrationsIDs = value
      .split(',')
      ?.map((item: any) => item?.trim().replace(/\D/g, ''))
      .filter((item: any) => item && +item);
  }
}
