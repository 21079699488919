import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import 'devextreme/data/odata/store';
import { PanelService } from 'src/app/services/panel.service';
import { ActivatedRoute } from '@angular/router';
import { IPanel } from 'src/app/interfaces/panel.interface';
import { IPanelStatusList } from 'src/app/interfaces/panel-status.interface';
import { PanelStatus } from 'src/app/enums/panel-status';

@Component({
  selector: 'app-panels',
  templateUrl: 'panel.component.html',
})
export class PanelComponent implements OnInit, OnDestroy {
  @Input() companyId!: number;
  @Input() receiverId!: number;
  visible: boolean;
  panels: IPanel[];
  interval: any;
  seedingMilliseconds: number;

  constructor(private panelService: PanelService) {
    this.panels = [];
    this.interval = {};
    this.visible = false;
    this.seedingMilliseconds = 60 * 1000;
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {}

  getStatusValue(cellInfo: any | undefined): any {
    if (cellInfo?.value !== undefined) {
      switch (cellInfo.value) {
        case PanelStatus.KeepFail:
          return 'Falha de Keep';
        case PanelStatus.Unknow:
          return 'Desconhecido';
        default:
          return PanelStatus[cellInfo.value];
      }
    }
  }

  onRowPrepared(e: any): void {
    if (e.rowType == 'header') return;

    const rowElem = e.rowElement;

    switch (e.data.status as PanelStatus) {
      case PanelStatus.Online:
        rowElem.style.backgroundColor = '#04c42a';
        break;
      case PanelStatus.Offline:
        rowElem.style.backgroundColor = 'Red';
        break;
      case PanelStatus.KeepFail:
        rowElem.style.backgroundColor = 'darkorange';
        break;
      case PanelStatus.Unknow:
        rowElem.style.backgroundColor = 'darkcyan';
        break;
    }
  }

  stopGettingPanels(): void {
    this.visible = false;

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async startGettingPanels(): Promise<void> {
    this.visible = true;

    await this.getPanels();

    this.interval = setInterval(() => {
      this.getPanels();
    }, this.seedingMilliseconds);
  }

  async getPanels(): Promise<void> {
    await this.panelService
      .getPanels(this.companyId, this.receiverId)
      .then((panels: IPanel[]) => {
        this.panels = panels;
        console.log('PANEL => clientCode: ', this.companyId);
        console.log('PANEL => instanceId: ', this.receiverId);
      });
  }
}
