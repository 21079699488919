import { ReceiverSettings } from '../models/receiver-settings';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { IReceiverSettings } from '../interfaces/receiver-settings.interface';
import { HttpClient } from '@angular/common/http';
import { ILogInfo } from '../interfaces/log-info.interface';

@Injectable()
export class ReceiverService {
  private baseUrl: string = environment.API.BASE_URL;

  constructor(private http: HttpClient) {}

  /**
   * @param companyId código do cliente
   * @return lista de receiver deste cliente
   */
  getReceivers(companyId: number): Promise<IReceiverSettings[]> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IReceiverSettings[]>(url, {}).toPromise();
  }

  getAllReceivers(): Promise<IReceiverSettings[]> {
    let url = `${this.baseUrl}/api/receivers`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IReceiverSettings[]>(url, {}).toPromise();
  }

  start(companyId: number, receiverId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/start`;

    return this.http.post<void>(url, {}).toPromise();
  }

  startAllByCompany(companyId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/start-all`;

    return this.http.post<void>(url, {}).toPromise();
  }

  stop(companyId: number, receiverId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/stop`;

    return this.http.post<void>(url, {}).toPromise();
  }

  stopAllByCompany(companyId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/stop-all`;

    return this.http.post<void>(url, {}).toPromise();
  }

  startLogging(companyId: number, receiverId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/start-logging`;

    return this.http.post<void>(url, {}).toPromise();
  }

  stopLogging(companyId: number, receiverId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/stop-logging`;

    return this.http.post<void>(url, {}).toPromise();
  }

  getLog(
    companyId: number,
    receiverId: number,
    date: Date | undefined
  ): Promise<ILogInfo[]> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/log`;

    if (date) {
      url += `?lastDateRead=${date}`;
    }

    console.log(url);

    return this.http.get<ILogInfo[]>(url, {}).toPromise();
  }

  create(receiverSettings: IReceiverSettings): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${receiverSettings.companyId}/receivers/create`;

    return this.http.post<void>(url, receiverSettings, {}).toPromise();
  }

  update(receiverSettings: IReceiverSettings): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${receiverSettings.companyId}/receivers/${receiverSettings.id}/update`;

    return this.http.put<void>(url, receiverSettings, {}).toPromise();
  }

  delete(companyId: number, receiverId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/delete`;

    return this.http.delete<void>(url, {}).toPromise();
  }
}
