<div class="card">
  <div class="content-block card-item">
    <div
      class="badges row"
      *ngIf="!receiver.serialSettings && receiver.running"
    >
      <div class="dx-badge online" title="Painéis Online">
        {{ receiver.panelStatusList?.onlineCount || 0 }}
      </div>
      <div class="dx-badge offline" title="Painéis Offline">
        {{ receiver.panelStatusList?.offlineCount || 0 }}
      </div>
      <div class="dx-badge keep-fail" title="Painéis com Falha de Keep">
        {{ receiver.panelStatusList?.keepFailCount || 0 }}
      </div>
      <div class="dx-badge unknow" title="Painéis Desconhecidos">
        {{ receiver.panelStatusList?.unknowCount || 0 }}
      </div>
    </div>
    <div
      class="dx-card pulse-red"
      [ngClass]="{ 'pulse-green': receiver.running }"
    >
      <div class="card-header-mine">
        <strong class="card-title text-center" title="{{ receiver.name }}">{{
          receiver.name
        }}</strong>
        <div class="header-btn-group">
          <dx-button
            *ngIf="receiver.running"
            hint="Visualizar comunicações"
            (onClick)="startLogging()"
            icon="print"
          ></dx-button>
          <dx-button
            *ngIf="!isAdmin || receiver.running"
            hint="Detalhes"
            (onClick)="details()"
            icon="info"
          ></dx-button>
          <dx-button
            *ngIf="isAdmin && !receiver.running"
            hint="Editar Configurações"
            (onClick)="edit()"
            icon="edit"
          ></dx-button>
          <dx-button
            *ngIf="isAdmin && !receiver.running"
            hint="Excluir Receiver"
            icon="trash"
            (onClick)="delete()"
          ></dx-button>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-form-label" for="text-input">Porta: </label>
        <span class="align-middle">{{ receiver.receiverPort }}</span>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-form-label" for="text-input"
          >Porta de Serviço:
        </label>
        <span class="align-middle">{{
          receiver.servicePort ? receiver.servicePort : "Inativa"
        }}</span>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-form-label" for="text-input">Instância: </label>
        <span class="align-middle">{{ receiver.instanceID }}</span>
      </div>
      <div *ngIf="showCompanyName" class="form-group row align-items-center">
        <label class="col-form-label" for="text-input">Empresa: </label>
        <span class="align-middle">{{ receiver.company?.name }}</span>
      </div>

      <dx-button
        *ngIf="isAdmin && !receiver.running"
        class="card-btn"
        stylingMode="contained"
        text="Iniciar"
        type="success"
        [disabled]="clicked"
        (onClick)="start()"
      >
      </dx-button>
      <dx-button
        *ngIf="isAdmin && receiver.running"
        class="card-btn"
        stylingMode="contained"
        text="Parar"
        type="danger"
        [disabled]="clicked"
        (onClick)="stop()"
      >
      </dx-button>

      <div class="card-btn-group" *ngIf="receiver.running">
        <dx-button
          class="card-btn"
          stylingMode="{{ seeingEvents ? 'contained' : 'outlined' }}"
          text="Eventos"
          type="default"
          [disabled]="clicked"
          (onClick)="getEvents()"
        >
        </dx-button>
        <dx-button
          class="card-btn"
          stylingMode="{{ seeingPanels ? 'contained' : 'outlined' }}"
          text="Painéis"
          type="default"
          [disabled]="clicked"
          (onClick)="getPanels()"
          *ngIf="!receiver.serialSettings"
        >
        </dx-button>
        <dx-button
          class="card-btn"
          stylingMode="{{ seeingConnections ? 'contained' : 'outlined' }}"
          text="Conexões"
          type="default"
          [disabled]="clicked"
          (onClick)="getConnections()"
          *ngIf="!receiver.serialSettings"
        >
        </dx-button>
      </div>

      <div
        [class]="{ list: seeingEvents || seeingPanels || seeingConnections }"
      >
        <!-- listagem de eventos -->
        <app-events
          [companyId]="receiver.companyId"
          [receiverId]="receiver.id"
        ></app-events>
        <!-- listagem de painéis -->
        <app-panels
          *ngIf="!receiver.serialSettings"
          [companyId]="receiver.companyId"
          [receiverId]="receiver.id"
        ></app-panels>
        <!-- listagem de conexões -->
        <app-connections
          *ngIf="!receiver.serialSettings"
          [companyId]="receiver.companyId"
          [receiverId]="receiver.id"
        ></app-connections>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN MODALS -->

<!-- Componente de Log -->
<app-logging></app-logging>

<!-- Componente de detalhes -->
<app-receiver-details></app-receiver-details>

<!-- Component de configuração -->
<app-receiver-update *ngIf="isAdmin"></app-receiver-update>

<!-- END MODALS -->
