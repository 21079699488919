import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ICompany } from 'src/app/interfaces/company.interface';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-company-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CompanyCreateComponent implements OnInit {
  company: ICompany;
  popupVisible: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCreated: EventEmitter<any> = new EventEmitter();

  constructor(private companyService: CompanyService) {
    this.company = new Company();
    this.popupVisible = false;
  }

  ngOnInit(): void {}

  showDialog(): void {
    console.log('showDialog');
    this.popupVisible = true;
  }

  createCompany(): void {
    this.companyService
      .create(this.company)
      .then((response) => {
        notify('Empresa criada com sucesso', 'success');
        this.popupVisible = false;
        this.company = new Company();
        this.onCreated.emit();
      })
      .catch((e) => {});
  }
}
